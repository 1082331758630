<template>
    <div> 

        <IconTopTable />
        <div class="content-table-results mt-2">
            <table class="table table-fixed table-stripes">
                <ColumnColGroup page="result" :column="data.column" :default_column="data.default_column" />
                <ColumnThead page="result" :column="data.column" :default_column="data.default_column" />
                <tbody> 
                    <template v-if="data.data.data && data.data.data.length > 0">
                        <tr v-for="(item, key) in data.data.data" :key="key" >                
                            
                            <td>
                                <label class="table-check">
                                    <input type="checkbox" v-model="select_export" :value="item.drug_id">
                                    <span class="checkbox-table"></span>
                                </label>
                            </td>
                            <td>
                                <router-link :to="'/detail/'+item.drug_id+'/'+item.agency_model.toLowerCase()" target="_blank" rel="noopener">
                                    <div class="icon-fiche"></div>
                                </router-link>
                                <div v-if="isAdmin()">
                                    <a :href="modifyPage(item.agency_model.toLowerCase(), item.drug_id)" target="_blank" rel="noopener"> 
                                        <em class="icon-edit"></em>
                                    </a>
                                </div>
                            </td>
                            <template v-for="(column) in data.column" :key="column.code" >


                                <td v-if="column.Status && column.code == 'spain_001'" :code="column.code">
                                    <span v-html="ema_id(item.links)"></span>
                                </td>
                                <td v-else-if="column.Status && column.code == 'spain_002'" :code="column.code">
                                    {{item.drug_id}}
                                </td>
                                <!-- <td v-else-if="column.Status && column.code == 'spain_003'" :code="column.code">
                                    <span v-html="icon_link(item.drug_id, 'spain')"></span>
                                </td> -->
                                <td v-else-if="column.Status && column.code == 'spain_004'" :code="column.code">
                                    {{check_empty(item.category)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'spain_005'" :code="column.code">
                                    {{check_empty(item.dci_new)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'spain_006'" :code="column.code">
                                    <span v-html="color(item.reimbursement_decision, 'reimbursement')"></span>
                                </td>
                                <td v-else-if="column.Status && column.code == 'spain_044'" :code="column.code">
                                    <span v-if="item.current_ma_status"  v-html="check_empty(overview_regulatory_status(item.current_ma_status))"></span>
                                </td>
                                <td v-else-if="column.Status && column.code == 'spain_007'" :code="column.code">
                                    <span v-html="column_status(item)"></span>
                                </td>
                                <td v-else-if="column.Status && column.code == 'spain_008'" :code="column.code">
                                    {{check_empty(DDMMMYY(item.spain.opinion_date))}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'spain_009'" :code="column.code">
                                    {{check_empty(DDMMMYY(item.ma_date))}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'spain_045'" :code="column.code">
                                    {{check_empty(DDMMMYY(item.spain.agenda_date))}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'spain_010'" :code="column.code">
                                    {{check_empty(item.name)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'spain_011'" :code="column.code">
                                    {{check_empty(item.dci)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'spain_012'" :code="column.code">                                
                                    {{check_empty(item.firm)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'spain_013'" :code="column.code">
                                    <span v-html="check_empty(array(item.atc))"></span>
                                </td>
                                <td v-else-if="column.Status && column.code == 'spain_014'" :code="column.code">
                                    <span v-html="check_empty(array(item['indexation_'+$i18n.locale]))"></span>
                                </td>
                                <td v-else-if="column.Status && column.code == 'spain_015'" :code="column.code">
                                    {{check_empty(item.spain.region)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'spain_016'" :code="column.code">
                                    {{check_empty(item.spain.agency)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'spain_017'" :code="column.code">
                                    <span v-html="decision(item.spain, 'decision', item.spain.decision_color)"></span>
                                </td>
                                <td v-else-if="column.Status && column.code == 'spain_018'" :code="column.code">
                                    <span v-html="decision(item.spain, 'reimbursement_decision', item.spain.reimbursement_decision_color)"></span>
                                </td>
                                <td v-else-if="column.Status && column.code == 'spain_019'" :code="column.code">                                
                                    {{check_empty(item.spain.reimbursement_decision_national)}}
                                </td>                            
                                <td v-else-if="column.Status && column.code == 'spain_020'" :code="column.code">
                                    <span v-html="color(item.prioritis_hta_index, 'limitation')"></span>
                                </td>                            
                                <template v-else-if="column.Status && column.code == 'spain_021'" colspan="4"  :code="column.code">
                                    <td :key="column.code+'_022'" :code="'spain_022'">
                                        <Readmore :longText="item.target_population"/>
                                    </td>
                                    <td :key="column.code+'_023'" :code="'spain_023'">
                                        <Readmore :longText="item.prescription_and_delivery_conditions"/>
                                    </td>
                                    <td :key="column.code+'_024'" :code="'spain_024'">
                                        <Readmore :longText="item.access_scheme"/>
                                    </td>
                                    <td :key="column.code+'_025'" :code="'spain_025'">
                                        <Readmore :longText="item.outcome_level"/>
                                    </td>
                                </template>
                                <td v-else-if="column.Status && column.code == 'spain_026'" :code="column.code">
                                    <Readmore :longText="item.rationale_and_comments_html_original"/>
                                </td>
                                <td v-else-if="column.Status && column.code == 'spain_027'" :code="column.code">
                                    {{check_empty(item.spain.submission_type_original)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'spain_028'" :code="column.code">
                                    <Readmore :longText="item.indication_en"/>
                                </td>
                                <td v-else-if="column.Status && column.code == 'spain_029'" :code="column.code">
                                    <span v-if="$i18n.locale === 'fr'" v-html="check_empty(array(item.classification_fr))"></span>
                                    <span v-else v-html="check_empty(array(item.classification_en))"></span>
                                </td>
                                <td v-else-if="column.Status && column.code == 'spain_030'" :code="column.code">
                                    {{check_empty_with_locale(item.mk_orphelin)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'spain_031'" :code="column.code">
                                    <span v-html="nct_number(item.nct_numbers)" @click="drug_id = item.drug_id"></span>
                                </td>
                                <td v-else-if="column.Status && column.code == 'spain_032'" :code="column.code">
                                    <span v-html="check_empty(treatment_line(item.treatment_line))"></span>
                                </td>
                                <td v-else-if="column.Status && column.code == 'spain_033'" :code="column.code">
                                    {{check_empty(item.specificity)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'spain_034'" :code="column.code">
                                    {{check_empty(item.administration)}}
                                </td>

                                <td v-else-if="column.Status && column.code == 'spain_046'" :code="column.code">
                                    {{check_empty_with_locale(item.spain.indirect_comparison_data) }}
                                </td>  
                                <td v-else-if="column.Status && column.code == 'spain_047'" :code="column.code">
                                    <Readmore :longText="item.spain.population_ic"/>             
                                </td> 
                                    <td v-else-if="column.Status && column.code == 'spain_048'" :code="column.code">
                                    {{check_empty_with_locale(item.spain.acepted_ic) }}
                                </td>   
                                <td v-else-if="column.Status && column.code == 'spain_049'" :code="column.code">
                                    {{check_empty_with_locale(item.spain.quality_of_life_data) }}
                                </td>  
                                <td v-else-if="column.Status && column.code == 'spain_050'" :code="column.code">
                                    <Readmore :longText="item.spain.type_of_qol_data_presented"/>             
                                </td> 
                                <td v-else-if="column.Status && column.code == 'spain_052'" :code="column.code">
                                    {{check_empty_with_locale(item.spain.accepted_qol) }}
                                </td>
                                
                                <template v-else-if="column.Status && column.code == 'spain_035'" :code="column.code">
                                    <td :key="column.code+'_036'" :code="'spain_036'" >
                                        {{check_empty(DDMMMYY(item.hta_process_date))}}
                                    </td>
                                    <td :key="column.code+'_037'" :code="'spain_037'">
                                        <span v-html="hta_process_status(item, 'hta_')"></span>
                                    </td>
                                    <td :key="column.code+'_038'" :code="'spain_038'">
                                        {{check_empty(DDMMMYY(item.rembursement_process_date))}}
                                    </td>
                                    <td :key="column.code+'_039'" :code="'spain_039'">
                                        <span v-html="rembursement_status(item.reimbursement_decision, 'rb_')"></span>
                                    </td>
                                </template>
                                <template v-else-if="column.Status && column.code == 'spain_040'" :code="column.code">
                                    <td :key="column.code+'_041'" :code="'spain_041'">
                                        <span data-bs-toggle="modal" data-bs-target="#modal-hta-status" @click="hta_status_modal(item)" v-html="hta_process_status_2(item)"></span>
                                    </td>
                                    <td :key="column.code+'_042'" :code="'spain_042'">
                                        <span data-bs-toggle="modal" data-bs-target="#modal-hta-status" @click="hta_status_modal(item)" v-html="rembursement_status_2(item)"></span>
                                    </td>
                                    <td :key="column.code+'_043'" :code="'spain_043'">
                                        <span v-html="datediff(item.ma_date, item.rembursement_process_date)"></span>
                                    </td>
                                </template>
                            
                            </template>

                        </tr>

                    </template>

                    <template v-else><tr><td colspan="99">{{$t('No Data')}}</td></tr></template>

                </tbody>

            </table>
        </div> 
        <ModalHTAstatus :item="item_hta_status" />
        <ModalEssaisClinique :id="drug_id" agency="Spain" />     
    </div>
</template>

<script>

import IconTopTable from '@/components/result/icon_top_table.vue';
import ColumnColGroup from '@/components/elements/column_colgroup.vue';
import ColumnThead from '@/components/elements/column_thead.vue';
import Readmore from '@/components/elements/readmore.vue';
import ModalHTAstatus from '@/components/result/modal_hta_status.vue';
import { isAdmin, column_status, hta_process_status, hta_process_status_2, rembursement_status, 
        rembursement_status_2, overview_regulatory_status, modifyPage, ema_id, icon_link, DDMMMYY, 
        check_empty, nct_number, treatment_line, datediff, array, color_rembursement, color_type_limitation,
        check_empty_with_locale } from '@/utils'
import ModalEssaisClinique from '@/components/elements/modal_essais_clinique.vue'
export default {
    name: "Spain",
    components: {
        IconTopTable,
        ColumnColGroup,
        ColumnThead,
        Readmore,
        ModalHTAstatus,
        ModalEssaisClinique
    },
    data : function() {
        return { 
            item_hta_status : {},
            drug_id : 0 //for essais clinique popup
        }
    },
     computed: {                
        data(){
            return this.$store.getters['result/data'];
        },
        select_export: {
            set(val){ this.$store.dispatch("result/select_export", val) },
            get(){ 
                if(this.$store.getters['result/select_export_checkAll']){
                    return true
                }else{
                    return this.$store.getters['result/select_export'] ? this.$store.getters['result/select_export'].listID : null 
                }
            }
        }
    },
    methods: {
        color: function(param, str = ''){
            if(param){
                if(str === 'limitation'){
                    return '<span class="' + this.color_type_limitation(param) + '">' + param + '</span>'
                }
                else if(str === 'reimbursement'){
                    return `<span class="${this.color_rembursement(param)}">${this.$t('rb_'+param)}</span>`
                } 
                else{
                    return '<span class="' + this.color_rembursement(param) + '">' + param + '</span>';
                }
            }
            else{
                return '-'
            }
        },
        column_status,
        hta_process_status,
        hta_process_status_2,
        rembursement_status,
        rembursement_status_2,
        isAdmin,
        ema_id,
        icon_link,
        DDMMMYY,
        check_empty,
        nct_number,
        treatment_line,
        datediff,
        array,
        color_rembursement,
        color_type_limitation,
        check_empty_with_locale,
        decision(param, str, color){
            let html = ''
            if(str === 'decision' && param.decision){
                html += '<span style="color: ' + color +';">'                
                html +=  param.decision ? param.decision : ''
                html += '</span>'   
            }
            else if(str === 'reimbursement_decision' && param.reimbursement_decision){
                html += '<span style="color: ' + color +';">'                
                html +=  param.reimbursement_decision ? param.reimbursement_decision : ''
                html += '</span>'
            }
            html = this.check_empty(html)
            return html  
        },
       
        getResults(page = 1) {
            console.log(page)
        },
        hta_status_modal(item){
            this.item_hta_status = item
        },
        overview_regulatory_status,
        modifyPage
    }
}
</script>

<style scoped>
/* CSS for fixed height of table for show in one page */
@media only screen and (max-width: 862px){
    .content-table-results{
        height: auto;
    }
}
@media only screen and (min-width: 863px){
    .content-table-results{
        max-height: 70vh;
    }
}
@media only screen and (min-height: 863px){
    .content-table-results{
        max-height: 65vh;
    }
}
@media only screen and (min-height: 1320px){
    .content-table-results{
        max-height: 75vh;
    }
}
</style>